export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getCoordinateString = (coordinate: { lat: string; lon: string }) => {
  if (!coordinate) return '';
  return coordinate.lat.slice(0, 10) + coordinate.lon.slice(0, 10);
};

export const trimVideoName = (name: string, maxLength = 20) => {
  if (!name) {
    return '';
  }

  if (name.length > maxLength) {
    const splitted = name.split('.');
    const extension = splitted.pop();
    const filename = splitted.join('.');

    const trimmedFileName = `${filename.slice(0, 8)}..${filename.slice(-2)}`;

    return `${trimmedFileName}.${extension}`;
  }
  return name;
};
