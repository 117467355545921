import { TextButton } from '@daangn/sprout-components-button';
import type { PropsOf } from 'utils/Type';

type SubButtonProps = PropsOf<typeof TextButton>;

const SubButton = (props: SubButtonProps) => {
  return (
    <div className="py-1.5">
      <TextButton variant="secondary" size="small" width="100%" bold {...props}>
        {props.children}
      </TextButton>
    </div>
  );
};

export default SubButton;
