import { ForwardedRef } from 'react';
import { forwardRef, Stack, StackProps } from './Stack';
import { cn } from 'styles/utils';
import { ComponentWithAs } from 'utils/Type';

export type HStackProps = Omit<StackProps, 'direction'>;
export const HStack = forwardRef((_props: HStackProps, ref: ForwardedRef<HTMLDivElement>) => {
  const props: StackProps = {
    ..._props,
    direction: 'row',
    className: cn('items-center', _props.className),
  };

  return <Stack {...props} ref={ref} />;
}) as ComponentWithAs<'div', StackProps>;

HStack.displayName = 'HStack';
