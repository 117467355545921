import React from 'react';
import { checkIsBigFont } from 'utils/style';
import { Stack, StackProps } from './Stack/Stack';
import { HStack } from './Stack/HStack';

type FlexDirection = 'row' | 'column';
type Props = StackProps & {
  direction?: FlexDirection;
  sameWidth?: boolean;
  children: React.ReactNode;
};

function ButtonGroup({ direction = 'row', sameWidth, children, ...props }: Props) {
  const isRow = direction === 'row' && !checkIsBigFont();
  const Wrapper = isRow ? HStack : Stack;

  return (
    <Wrapper spacing={8} {...props}>
      {React.Children.map(children, (child) => {
        if (!isRow || !child) {
          return child;
        }

        return (
          <div className={sameWidth ? 'flex-1' : 'first-of-type:shrink-0 last-of-type:w-full'}>
            {child}
          </div>
        );
      })}
    </Wrapper>
  );
}

export default ButtonGroup;
