import ArticleCardSkeleton from 'components/Base/Skeleton/ArticleCardSkeleton';
import { Stack } from '../Stack/Stack';
import Divider from '../Divider';

const ArticleListSkeleton = () => {
  return (
    <Stack spacing={16} divider={<Divider hideOnEdge />}>
      {[...Array(10)].map((_, i) => (
        <ArticleCardSkeleton key={i} />
      ))}
    </Stack>
  );
};

export default ArticleListSkeleton;
