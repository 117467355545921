import { IconMagnifyingglassLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import { forwardRef } from 'react';
import TextField from 'components/Base/TextField';
import type { PropsOf } from 'utils/Type';
import TextFieldRemoveButton from 'components/Base/TextFieldRemoveButton';

type Props = Omit<
  PropsOf<typeof TextField> & {
    onClickDelete: () => void;
  },
  'size' | 'prefix' | 'suffix'
>;

const SearchField = forwardRef<HTMLInputElement, Props>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ({ value, onClickDelete, ...props }: Props, ref) => {
    return (
      <TextField
        ref={ref}
        size="small"
        value={value}
        prefix={
          <IconMagnifyingglassLine color={vars.$scale.color.gray500} width={16} height={16} />
        }
        suffix={!!value && <TextFieldRemoveButton onClickDelete={onClickDelete} />}
        {...props}
      />
    );
  }
);

export default SearchField;
