import { vars } from '@seed-design/design-token';
import { SectionContainer } from 'components/Base/Container';
import Placeholder from 'components/Base/Skeleton/Placeholder';
import { Stack } from 'components/Base/Stack/Stack';
import { HStack } from 'components/Base/Stack/HStack';
import Divider from 'components/Base/Divider';

const ArticleDetailPageSkeleton = () => {
  return (
    <div
      style={{
        minHeight: '100vh',
        background: vars.$semantic.color.paperDefault,
      }}
    >
      <Placeholder.Box className="h-[100vw] w-full" />
      <SectionContainer>
        <Stack spacing={16} divider={<Divider />} className="pt-5">
          <HStack spacing={12}>
            <Placeholder.Box className="h-[48px] w-[48px] rounded-full" />
            <Stack spacing={2}>
              <Placeholder.Text className="subtitle1Bold">당근마켓</Placeholder.Text>
              <Placeholder.Text className="label3Regular">서초동</Placeholder.Text>
            </Stack>
          </HStack>
          <Stack spacing={8}>
            <Stack spacing={4}>
              <Placeholder.Text className="bodyM2Regular">오픈형 원룸</Placeholder.Text>
              <Placeholder.Text className="title1Bold">월세 500/40</Placeholder.Text>
            </Stack>
            <div className="py-3">
              <Placeholder.Text className="label3Bold rounded-2.5 w-full border border-transparent p-3.5">
                직거래로 아끼는 비용
              </Placeholder.Text>
            </div>
            <div>
              <Placeholder.Text className="subtitle2Regular">
                8일 전 · 채팅 0 · 관심 0 · 조회 7
              </Placeholder.Text>
            </div>
          </Stack>
        </Stack>
      </SectionContainer>
    </div>
  );
};

export default ArticleDetailPageSkeleton;
