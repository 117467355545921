import { vars } from '@seed-design/design-token';
import { ComponentPropsWithoutRef } from 'react';
import { cn } from 'styles/utils';

const allTextColor = {
  ...vars.$semantic.color,
  ...vars.$static.color,
  ...vars.$scale.color,
};

type TextColor = keyof typeof allTextColor;
type TextVariant = keyof typeof vars.$semantic.typography;
export type TextEllipsisVariant = 1 | 2 | 3 | 4 | 5;

type Props = {
  variant: TextVariant;
  color?: TextColor;
  ellipsis?: TextEllipsisVariant;
} & Omit<ComponentPropsWithoutRef<'p'>, 'variant' | 'color'>;

export const textEllipsisVariants: Record<TextEllipsisVariant, string> = {
  1: 'text-ellipsis-1',
  2: 'text-ellipsis-2',
  3: 'text-ellipsis-3',
  4: 'text-ellipsis-4',
  5: 'text-ellipsis-5',
};

const Text = ({ variant, color, ellipsis, style, ...props }: Props) => {
  return (
    <p
      className={cn(ellipsis && textEllipsisVariants[ellipsis], props.className)}
      style={{
        ...vars.$semantic.typography[variant],
        color: color && allTextColor[color],
        ...style,
      }}
      {...props}
    />
  );
};

export default Text;
