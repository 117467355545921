import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  type ReactElement,
} from 'react';
import { AnimatePresence } from 'framer-motion';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import useDarkMode from 'hooks/useDarkmode';
import Logger from 'utils/Logger';
import VisibilityMotion from 'components/Base/VisibilityMotion';
import ConditionalWrapper from 'components/Base/ConditionalWrapper';
import Divider from 'components/Base/Divider';
import useCombinedRefs from 'hooks/useCombinedRefs';
import PageLevelModal from 'components/Base/Portal/PagePortal';
import Dim from 'components/Base/Dim';
import Handler from './Handler';
import { LoggerComponentProps } from 'logger/types/common';
import AvoidToast from 'components/Toast/AvoidToast';
import { HStack } from 'components/Base/Stack/HStack';
import { Stack } from 'components/Base/Stack/Stack';
import { tv } from 'tailwind-variants';
import { cn } from 'styles/utils';

export type BottomSheetLoggerProps = LoggerComponentProps<
  'bottom_sheet' | 'click_bottom_sheet_dim'
>;

export type BottomSheetProps = {
  isOpen: boolean;
  isPageLevel?: boolean;
  title?: ReactNode;
  description?: ReactNode;
  footer?: ReactNode;
  animatePresence?: boolean;
  showCloseButton?: boolean;
  showDivider?: boolean;
  showDimmed?: boolean;
  closeOnDimClick?: boolean;
  avoidToast?: boolean;
  contentProps?: React.HTMLAttributes<HTMLDivElement>;
  scrollRef?: React.RefObject<HTMLDivElement>;
  handle?: {
    minHeight: number;
  };
  onClose: () => void;
  className?: string;
} & BottomSheetLoggerProps;

const bottomSheet = tv({
  slots: {
    wrapper: [
      'flex flex-col',
      'absolute bottom-0 left-0 right-0 w-full',
      'max-h-[calc(100vh-env(safe-area-inset-top)-var(--stackflow-plugin-basic-ui-app-bar-height))]',
      'overflow-hidden pb-[env(safe-area-inset-bottom)]',
      'z-modal bg-paperDefault rounded-t-[20px]',
    ],
    header: ['relative flex items-center', 'z-docked', 'px-4 pb-4 pt-5', 'empty:p-0'],
    titleWrapper: ['w-full flex-shrink-0 flex-grow'],
    title: 'title2Bold',
    description: 'bodyM1Regular text-gray700',
    closeButton: [
      'absolute right-4 top-4',
      'text-gray900 flex items-center',
      'p-0 py-[3px] pl-[3px]',
    ],
    contents: 'relative flex overflow-y-hidden',
    contentsScroll: 'flex-1 overflow-y-auto px-4',
    footer: 'px-4 pb-2 pt-3',
    titlePadding: 'flex-shrink-1 w-6 flex-grow-0',
  },
});

function BottomSheet({
  isOpen,
  title: titleProps,
  description,
  isPageLevel = true,
  showCloseButton = true,
  closeOnDimClick = true,
  animatePresence = true,
  showDimmed = true,
  showDivider,
  avoidToast,
  onClose,
  children,
  footer,
  event,
  scrollRef: scrollRefProps,
  contentProps,
  handle,
  className,
}: PropsWithChildren<BottomSheetProps>) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const mergedScrollRef = useCombinedRefs(scrollRef, scrollRefProps);
  const [showGradient, setShowGradient] = useState(false);
  const isDarkMode = useDarkMode();
  const {
    wrapper,
    header,
    titleWrapper,
    title,
    description: descriptionStyle,
    closeButton,
    contents,
    contentsScroll,
    footer: footerStyle,
    titlePadding,
  } = bottomSheet();

  const handleDimClick = () => {
    if (closeOnDimClick) {
      event && Logger.track('click_bottom_sheet_dim', event.params);
      onClose();
    }
  };

  const handleCloseClick = () => {
    if (showCloseButton) {
      event && Logger.track('click_bottom_sheet_close', event.params);
      onClose();
    }
  };

  useLayoutEffect(() => {
    if (!scrollRef.current) return;

    const $scroll = scrollRef.current;
    const handleScroll = () => {
      const hasScroll = $scroll.scrollHeight > $scroll.clientHeight;
      const isScrollEnd = $scroll.scrollHeight - $scroll.scrollTop === $scroll.clientHeight;
      setShowGradient(hasScroll && !isScrollEnd);
    };

    handleScroll();
    $scroll.addEventListener('scroll', handleScroll);
    return () => $scroll.removeEventListener('scroll', handleScroll);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && event) {
      Logger.track('bottom_sheet', event.params);
    }
  }, [isOpen]);

  return (
    <>
      <ConditionalWrapper
        condition={animatePresence}
        wrapper={(children) => <AnimatePresence>{children}</AnimatePresence>}
      >
        {isOpen && (
          <ConditionalWrapper
            condition={isPageLevel}
            wrapper={(children) => (
              <PageLevelModal
                isOpen
                onClose={onClose}
                onDimClick={handleDimClick}
                closeOnDimClick={closeOnDimClick}
              >
                {showDimmed && <PageLevelModal.Dim />}
                <PageLevelModal.Body>{children}</PageLevelModal.Body>
              </PageLevelModal>
            )}
          >
            {!isPageLevel && showDimmed && <Dim />}
            <ConditionalWrapper
              condition={!!avoidToast}
              wrapper={(children) => <AvoidToast>{children as ReactElement}</AvoidToast>}
            >
              <VisibilityMotion
                ref={wrapperRef}
                key="bottom_sheet"
                show={{ y: 0 }}
                hide={{ y: '100%' }}
                initial="hide"
                animate="show"
                exit="hide"
                className={cn(wrapper(), className)}
              >
                {handle && (
                  <Handler wrapperRef={wrapperRef} minHeight={handle.minHeight} event={event} />
                )}

                <HStack spacing={0} className={header()}>
                  {titleProps && (
                    <>
                      <div className={titlePadding()} />
                      <Stack spacing={6} className={cn(titleWrapper(), 'word-break-text')}>
                        <h3 className={title()}>{titleProps}</h3>
                        {description && <p className={descriptionStyle()}>{description}</p>}
                      </Stack>
                      <div className={titlePadding()} />
                    </>
                  )}
                  {showCloseButton && (
                    <button className={closeButton()} onClick={handleCloseClick}>
                      <IconXmarkLine width={24} height={24} />
                    </button>
                  )}
                </HStack>
                {showDivider && <Divider className="mx-4" />}
                <div
                  className={cn(
                    contents(),
                    showGradient &&
                      'after:absolute after:bottom-0 after:h-10 after:w-full after:bg-gradient-to-b after:content-[""]',
                    showGradient && isDarkMode
                      ? 'after:from-[rgba(33,33,36,0)] after:via-[rgba(33,33,36,0.7)] after:to-[#212124]'
                      : 'after:from-[rgba(255,255,255,0)] after:via-[rgba(255,255,255,0.7)] after:to-[#FFFFFF]'
                  )}
                >
                  <div ref={mergedScrollRef} className={contentsScroll()} {...contentProps}>
                    {children}
                  </div>
                </div>
                {footer && <div className={footerStyle()}>{footer}</div>}
              </VisibilityMotion>
            </ConditionalWrapper>
          </ConditionalWrapper>
        )}
      </ConditionalWrapper>
    </>
  );
}

export default BottomSheet;
