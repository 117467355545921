import ArticleListSkeleton from 'components/Base/Skeleton/ArticleListSkeleton';
import Placeholder from 'components/Base/Skeleton/Placeholder';
import PlaceHolder from 'components/Base/Skeleton/Placeholder';
import { HStack } from '../Stack/HStack';

const MainSectionSkeleton = () => {
  return (
    <div className="pt-1">
      <div className="pb-1">
        <Placeholder.Text className="bodyM2Regular rounded-1.5 w-full p-4">
          1개 조건으로 알림 받는 중
        </Placeholder.Text>
      </div>
      <div className="h-1" />
      <HStack spacing={8} className="pb-2 pt-1">
        <PlaceHolder.Chip size="small" width={96} />
        <PlaceHolder.Chip size="small" width={124} />
        <PlaceHolder.Chip size="small" width={68} />
        <PlaceHolder.Chip size="small" width={68} />
      </HStack>
      <div className="py-4">
        <ArticleListSkeleton />
      </div>
    </div>
  );
};

export default MainSectionSkeleton;
