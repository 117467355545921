import { cn } from 'styles/utils';
import { type HTMLAttributes } from 'react';
import { Stack, type StackProps } from 'components/Base/Stack/Stack';
import { HStack, type HStackProps } from 'components/Base/Stack/HStack';

const ArticleCardStyle = {
  Container: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={cn('bg-paperDefault', className)} {...props} />
  ),
  Body: ({ className, ...props }: HStackProps) => (
    <HStack className={cn('flex items-stretch gap-4', className)} spacing={16} {...props} />
  ),
  Content: ({ className, ...props }: StackProps) => (
    <Stack spacing={6} className={cn('w-full', className)} {...props} />
  ),
  Label: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <h3 className={cn('label4Bold', className)} {...props} />
  ),
  Title: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <h3 className={cn('subtitle1Bold', className)} {...props} />
  ),
  SubTitle: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <h4 className={cn('subtitle2Regular', className)} {...props} />
  ),
  Information: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={cn('caption1Regular text-gray700 text-ellipsis-2', className)} {...props} />
  ),
  DateText: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={cn('caption2Regular text-gray600 text-ellipsis-1', className)} {...props} />
  ),
  AdvertisementHelper: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div
      className={cn('caption2Regular text-gray600 inline-flex flex-shrink-0', className)}
      {...props}
    />
  ),
};

export default ArticleCardStyle;
