import { IconArrowClockwiseCircularLine } from '@daangn/react-monochrome-icon';
import BoxButton from 'components/Base/BoxButton';
import BottomSheet from 'components/BottomSheet/BottomSheet';
import withDev from 'hoc/withDev';
import useDisclosure from 'hooks/useDisclosure';
import React, {
  FormEvent,
  useEffect,
  useRef,
  useState,
  type ChangeEvent,
  useMemo,
  Suspense,
} from 'react';
import sessionState from 'store/session';
import { checkIsAmplitudeTargetUser } from 'utils/amplitude';
import DoorBottomSheet from 'components/Dev/DoorBottomSheet';
import useCustomFlow from 'hooks/useCustomFlow';
import { IS_PROD_TEST, IS_REAL, OVERRIDE_API_HOST } from 'config';
import { overwriteQuery } from 'utils/Url';
import TextField from 'components/Base/TextField';
import { LocalStorage, SessionStorage } from 'utils/Storage';
import GroupMenuItem from 'components/Dev/GroupMenuItem';
import MainListMenuItem from 'components/Dev/MainListMenuItem';
import Text from 'components/Base/Text';
import { debugDateState } from 'store/debug';
import { useToday } from 'hooks/useToday';
import { Slider } from '@daangn/sprout-components-slider';
import { useAtomValue, useSetAtom } from 'jotai';
import DeploymentList from 'components/Dev/DeploymentList';
import useIdentification from 'hooks/useIdentification';
import { actionToast } from 'utils/actionToast';
import { Stack } from 'components/Base/Stack/Stack';
import { HStack } from 'components/Base/Stack/HStack';
import Divider from 'components/Base/Divider';

const DEFAULT_FONT_SIZE = 16;

const MagicButton = () => {
  const [fontSize, setFontSize] = useState(
    Number(document.documentElement.style.fontSize || DEFAULT_FONT_SIZE)
  );
  const [pos, setPos] = useState({
    left: 10,
    top: window.innerHeight - 100,
  });
  const startPos = useRef({ x: 0, y: 0 });
  const [isDevOpen, devOpenHandler] = useDisclosure();
  const [isDoorOpen, doorHandler] = useDisclosure();
  const { user } = useAtomValue(sessionState);
  const setDebugDate = useSetAtom(debugDateState);
  const today = useToday();
  const { push } = useCustomFlow();
  const localDateTime = useMemo(() => {
    const now = new Date(today);
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
    return now.toISOString().slice(0, 16);
  }, [today]);

  const { checkAndCreateIdentification } = useIdentification({
    title: '본인인증 테스트',
    description: '본인인증 설명 테스트',
    type: 'dev',
  });

  const handleStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const { left, top } = (e.currentTarget as HTMLDivElement).getBoundingClientRect();
    const touch = e.touches[0];
    startPos.current.x = left - touch.clientX;
    startPos.current.y = top - touch.clientY;
  };

  const handleMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const { height, width } = (e.currentTarget as HTMLDivElement).getBoundingClientRect();
    const touch = e.touches[0];
    setPos({
      left: Math.min(Math.max(startPos.current.x + touch.clientX, 0), window.innerWidth - width),
      top: Math.max(Math.min(startPos.current.y + touch.clientY, window.innerHeight - height), 0),
    });
  };

  const handleFontSizeChange = (v: number) => {
    setFontSize(v);
    document.documentElement.style.fontSize = `${v}px`;
  };

  const handleReferrerChange = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const referrer = e.currentTarget.referrer.value;
    if (referrer) {
      window.location.href = overwriteQuery(window.location.href, { referrer });
    }
  };

  const handleOverrideApiHostChange = (endpoint: string) => {
    window.location.href = overwriteQuery(window.location.href, {
      override_api_host: endpoint,
    });
  };

  const handleOverrideApiHostFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const overrideApiHost = e.currentTarget.overrideApiHost.value;
    overrideApiHost && handleOverrideApiHostChange(overrideApiHost);
  };

  const handleBrokerChange = () => {
    window.location.href = overwriteQuery(window.location.href, {
      is_broker: 'true',
    });
  };

  const handleDebugDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    setDebugDate(new Date(date));
  };

  const handleIdentificationTest = async () => {
    const result = await checkAndCreateIdentification();
    actionToast({
      message: result.success ? `성공 (${result.result.realName})` : '실패',
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPos((prev) => ({ ...prev, top: window.innerHeight - 100 }));
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {IS_PROD_TEST && (
        <div
          className="z-outermost fixed left-1/2 -translate-x-1/2"
          style={{ top: 'env(safe-area-inset-top, 0px)' }}
        >
          <Text variant="caption2Bold" color="danger" className="bg-black">
            ⚠️ 프로덕션 테스트 환경 주의
          </Text>
        </div>
      )}
      <HStack
        style={{ top: pos.top, left: pos.left }}
        onTouchStart={handleStart}
        onTouchMove={handleMove}
        className="z-outermost text-gray900 fixed px-[5px]"
      >
        <BoxButton size="xsmall" variant="primary" onClick={devOpenHandler.onOpen}>
          DEV
        </BoxButton>
        <button
          onClick={() => window.location.reload()}
          className="bg-gray100 flex items-center justify-center rounded-full p-[5px] text-inherit"
        >
          <IconArrowClockwiseCircularLine width={24} height={24} />
        </button>
      </HStack>
      <BottomSheet className="max-h-[80vh]" isOpen={isDevOpen} onClose={devOpenHandler.onClose}>
        <Stack onClick={devOpenHandler.onClose} divider={<Divider type="base" />}>
          <MenuItem
            onClick={() => {
              devOpenHandler.onClose();
              doorHandler.onOpen();
            }}
          >
            🚪 어디로든문
          </MenuItem>
          {!IS_REAL && (
            <MenuItem onClick={() => push('alpha_login', {})}>
              테스트 계정 로그인 (프로덕션 포함)
            </MenuItem>
          )}
          <MenuItem>
            <div onClick={(e) => e.stopPropagation()}>
              <MainListMenuItem />
            </div>
          </MenuItem>
          <MenuItem>
            <Stack spacing={8}>
              <label>빅폰트 {fontSize}px</label>
              <div className="px-2">
                <Slider
                  value={fontSize}
                  minValue={16}
                  maxValue={30}
                  step={2}
                  onChange={(v) => handleFontSizeChange(v as number)}
                />
              </div>
            </Stack>
          </MenuItem>
          <MenuItem>
            <Stack spacing={8} onClick={(e) => e.stopPropagation()}>
              <label>날짜 변경 (배너 테스트 등)</label>
              <input type="datetime-local" value={localDateTime} onChange={handleDebugDateChange} />
            </Stack>
          </MenuItem>
          <MenuItem onClick={handleBrokerChange}>중개사로 들어오기</MenuItem>
          <MenuItem>
            <Stack spacing={8} onClick={(e) => e.stopPropagation()}>
              <label>레퍼러 바꾸기</label>
              <form onSubmit={handleReferrerChange}>
                <HStack className="items-start">
                  <TextField
                    defaultValue={SessionStorage.getReferrer() ?? ''}
                    size="small"
                    name="referrer"
                    flexGrow={1}
                  />
                  <BoxButton type="submit">변경</BoxButton>
                </HStack>
              </form>
            </Stack>
          </MenuItem>
          <MenuItem>
            <Stack spacing={8} onClick={(e) => e.stopPropagation()}>
              <label>서버 호스트 바꾸기</label>
              <form onSubmit={handleOverrideApiHostFormSubmit}>
                <HStack className="items-start">
                  <TextField
                    defaultValue={OVERRIDE_API_HOST ?? ''}
                    size="small"
                    name="overrideApiHost"
                    flexGrow={1}
                  />
                  <BoxButton type="submit">변경</BoxButton>
                </HStack>
              </form>
              <Suspense fallback={null}>
                <DeploymentList onClick={handleOverrideApiHostChange} />
              </Suspense>
            </Stack>
          </MenuItem>
          <MenuItem>
            <div onClick={(e) => e.stopPropagation()}>
              <GroupMenuItem />
            </div>
          </MenuItem>
          <MenuItem
            onClick={() => window.alert(JSON.stringify(LocalStorage.getUserSegments(), null, 2))}
          >
            내 세그먼트 확인
          </MenuItem>
          <MenuItem
            onClick={async () => {
              const isTarget = await checkIsAmplitudeTargetUser(
                user?.id,
                LocalStorage.getUserSegments()
              );
              window.alert(isTarget ? 'O' : 'X');
            }}
          >
            앰플리튜드 샘플링 여부 확인
          </MenuItem>
          <MenuItem onClick={handleIdentificationTest}>본인인증 테스트</MenuItem>
        </Stack>
      </BottomSheet>
      <DoorBottomSheet isOpen={isDoorOpen} onClose={doorHandler.onClose} />
    </>
  );
};

export default withDev(MagicButton);

const MenuItem = ({ onClick, children, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className="label2Bold px-2 py-4" onClick={onClick} {...props}>
    {children}
  </div>
);
