import Dim from 'components/Base/Dim';
import { Spinner } from '@daangn/sprout-components-spinner';

type Props = {
  dimmed?: boolean;
};

const GlobalLoading = ({ dimmed }: Props) => {
  return (
    <>
      {dimmed && <Dim />}
      <div className="z-outermost absolute left-0 top-0 flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    </>
  );
};

export default GlobalLoading;
