import { cn } from 'styles/utils';
import { type HTMLAttributes } from 'react';

const baseStyles = cn(
  'relative overflow-hidden bg-gray100 [mask-image:radial-gradient(white,black)]'
);
const animationStyles = cn(
  'after:absolute after:inset-0 after:block after:min-w-40',
  'after:animate-[placeholder_2s_ease-in-out_infinite]',
  'after:bg-[linear-gradient(to_left,rgba(0,10,20,0),rgba(0,10,20,0.075),rgba(0,10,20,0))]'
);

type ChipProps = HTMLAttributes<HTMLDivElement> & {
  size?: 'small' | 'medium';
  width?: number;
};

const Box = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn(baseStyles, animationStyles, className)} {...props} />
);

const Text = ({ className, children, ...props }: HTMLAttributes<HTMLSpanElement>) => (
  <span
    className={cn(
      baseStyles,
      animationStyles,
      'text-gray100 inline-block w-fit empty:after:relative empty:after:inline empty:after:content-["로딩_중"]',
      className
    )}
    {...props}
  >
    {children}
  </span>
);

const Chip = ({ size = 'medium', width: customWidth, className, ...props }: ChipProps) => (
  <div
    className={cn(baseStyles, animationStyles, 'rounded-full', className)}
    style={{
      width: `${customWidth ?? 62}px`,
      height: size === 'small' ? '32px' : '38px',
    }}
    {...props}
  />
);

const Placeholder = {
  Box,
  Text,
  Chip,
};

export default Placeholder;
