import { Radio, RadioGroup } from '@daangn/sprout-components-radio-group';
import { Switch } from '@daangn/sprout-components-switch';
import BoxButton from 'components/Base/BoxButton';
import InputNumber from 'components/Base/InputNumber';
import Spacer from 'components/Base/Spacer';
import { HStack } from 'components/Base/Stack/HStack';
import { Stack } from 'components/Base/Stack/Stack';
import withDev from 'hoc/withDev';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { mainListDebugState, type ArticlePointOrder } from 'store/mainListDebug';
import {
  ArticlePointOrderDirection,
  ArticlePointOrderKey,
  TradedTypeSurveyEnum,
} from 'types/schemaEnums';
import { actionToast } from 'utils/actionToast';

const OrderDirections = [
  { label: '오름차순', value: 'ASC' },
  { label: '내림차순', value: 'DESC' },
] as const;

const OrderKeys = [
  { label: '최신순', value: 'LATEST' },
  { label: '끌올 순', value: 'PUBLISHED_AT' },
  { label: '관심순', value: 'WATCH' },
  {
    label: '거래완료까지 걸린 시간 순',
    value: 'TRADED_ELAPSED_HOURS',
  },
] as const;

const TradeTypes = ['BROKER', 'IN_KARROT', 'OTHER_PLATFORM'] satisfies TradedTypeSurveyEnum[];

const MainListMenuItem = () => {
  const [mainListDebug, setMainListDebug] = useAtom(mainListDebugState);
  const [limit, setLimit] = useState(String(mainListDebug?.limit ?? ''));

  const handleChangeOrder = (order: Partial<ArticlePointOrder>) => {
    setMainListDebug((prev) =>
      prev
        ? {
            ...prev,
            order: {
              orderDirection: 'DESC',
              orderKey: 'LATEST',
              ...prev.order,
              ...order,
            },
          }
        : null
    );
  };

  const handleApplyLimit = () => {
    const limitNumber = Number(limit);
    if (Number.isNaN(limitNumber)) {
      actionToast({
        message: 'limit은 숫자만 가능',
      });
      return;
    }
    setMainListDebug((prev) => ({
      ...prev,
      limit: limitNumber,
    }));
  };

  const handleResetLimit = () => {
    setMainListDebug((prev) => ({
      ...prev,
      limit: undefined,
    }));
    setLimit('');
  };

  const handleChangeTradedType = (tradedType: TradedTypeSurveyEnum | 'ALL') => {
    setMainListDebug((prev) => ({
      ...prev,
      tradedType: tradedType === 'ALL' ? undefined : tradedType,
    }));
  };

  return (
    <Stack spacing={8}>
      <HStack>
        <label className="label2Bold">거래완료 / 삭제된 게시글도 보기 여부</label>
        <Spacer />
        <Switch
          isSelected={!!mainListDebug}
          onChange={(v) => {
            setMainListDebug((prev) =>
              v
                ? {
                    ...prev,
                    order: {
                      orderDirection: 'DESC',
                      orderKey: 'LATEST',
                    },
                  }
                : null
            );
          }}
        />
      </HStack>
      {!!mainListDebug && (
        <Stack spacing={8}>
          <HStack>
            <label className="label4Bold">거래완료된 매물만 보기</label>
            <Spacer />
            <Switch
              isSelected={!!mainListDebug.isTraded}
              onChange={(v) =>
                setMainListDebug((prev) => ({
                  ...prev,
                  isTraded: v,
                }))
              }
            />
          </HStack>
          <label className="label4Bold">방향</label>
          <RadioGroup
            size="small"
            orientation="horizontal"
            onChange={(v) =>
              handleChangeOrder({
                orderDirection: v as ArticlePointOrderDirection,
              })
            }
            value={mainListDebug.order?.orderDirection}
          >
            {OrderDirections.map(({ label, value }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </RadioGroup>
          <label className="label4Bold">키</label>
          <RadioGroup
            size="small"
            orientation="horizontal"
            onChange={(v) =>
              handleChangeOrder({
                orderKey: v as ArticlePointOrderKey,
              })
            }
            value={mainListDebug.order?.orderKey}
          >
            {OrderKeys.map(({ label, value }) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </RadioGroup>
          <label className="label4Bold">거래완료된 곳</label>
          <RadioGroup
            size="small"
            orientation="horizontal"
            onChange={(v) => handleChangeTradedType(v as TradedTypeSurveyEnum | 'ALL')}
            value={mainListDebug.tradedType ?? 'ALL'}
          >
            {[
              <Radio key="ALL" value="ALL">
                전체
              </Radio>,
              ...TradeTypes.map((value) => (
                <Radio key={value} value={value}>
                  {value}
                </Radio>
              )),
            ]}
          </RadioGroup>
          <label className="label4Bold">LIMIT</label>
          <HStack className="items-center">
            <InputNumber size="small" value={limit} onChange={(v) => setLimit(v)} />
            <Spacer />
            <BoxButton size="small" variant="secondary" onClick={handleResetLimit}>
              초기화
            </BoxButton>
            <BoxButton size="small" onClick={handleApplyLimit}>
              적용
            </BoxButton>
          </HStack>
        </Stack>
      )}
    </Stack>
  );
};

export default withDev(MainListMenuItem);
